import styled from '@emotion/styled';

import BaseLink from '@/components/common/BaseLink/BaseLink';
import { screen } from '@/components/common/breakpoints';
import { BulletPoints } from '@/components/common/bulletpoints';
import { Button } from '@/components/common/Buttons';
import { CaptionHeadingDescription } from '@/components/common/CaptionHeadingDescription';
import { Heading, HeadingLevel, Paragraph } from '@/components/common/MarkUp';
import { PortableText } from '@/components/common/utils';
import { Col, Row } from '@/components/layout/Grid';
import { Section } from '@/components/layout/Section';
import { Stack } from '@/components/layout/Stack';
import {
  getBulletPointColour,
  getButtonComponentThemeFlat,
  getTextColour,
} from '@/lib/colourUtils';
import { blockUrlWithAudience } from '@/lib/utils';
import { headingMedium, subheadingSmall } from '@/styles/typography';

import type {
  SanityAudienceType,
  SanityKeyed,
  SanityTwoColumnHeadingTextType,
} from '@/types/sanity';
import type { BlockWithAudience } from '@/types/shared';

interface CtaLeftProps
  extends Omit<SanityTwoColumnHeadingTextType['ctaLeft'], 'audienceRef'> {
  audienceRef?: SanityAudienceType;
}
interface CtaRightProps
  extends Omit<SanityTwoColumnHeadingTextType['ctaRight'], 'audienceRef'> {
  audienceRef?: SanityAudienceType;
}

export interface TwoColumnHeadingTextProps
  extends Omit<
      SanityTwoColumnHeadingTextType,
      'ctaLeft' | 'ctaRight' | 'links'
    >,
    BlockWithAudience {
  ctaLeft?: CtaLeftProps;
  ctaRight?: CtaRightProps;
  links?: Array<
    SanityKeyed<{
      ctaText?: string;
      audienceRef?: SanityAudienceType;
      noAudienceToggle?: boolean;
      ctaAction?: string;
    }>
  >;
}

export const TwoColumnHeadingText = ({
  heading,
  backgroundColour,
  blockSpacing,
  paddingAdjustment,
  text,
  ctaRight,
  ctaLeft,
  links,
  pageAudience,
  isAudienceSwitcherEnabled,
}: TwoColumnHeadingTextProps) => {
  const backgroundColourToken = backgroundColour?.token;

  return (
    <Section
      verticalPadding={paddingAdjustment ?? true}
      spacing={blockSpacing}
      backgroundColour={backgroundColourToken}
      colour={getTextColour(backgroundColourToken)}
      className="two-column-heading-text"
    >
      <Row rowGap="--spacing-x-large">
        <HeadingLevel>
          <Col
            sizes={{
              base: 4,
              md: 5,
            }}
          >
            <HeadingContainer>
              <Stack spacing="--spacing-large">
                <CaptionHeadingDescription
                  heading={heading}
                  defaultHeadingSize="display-md"
                />
                {!!ctaLeft?.ctaAction && (
                  <ButtonContainer>
                    <Button
                      variant={getButtonComponentThemeFlat(
                        backgroundColourToken,
                      )}
                      href={blockUrlWithAudience(
                        ctaLeft.ctaAction,
                        ctaLeft.audienceRef,
                        pageAudience,
                        ctaLeft.noAudienceToggle,
                        isAudienceSwitcherEnabled,
                      )}
                      aria-label={ctaLeft.buttonAccessibleLabel}
                      className={
                        ctaLeft.trackingTag?.blockName &&
                        `tracking-${ctaLeft.trackingTag.blockName}__${ctaLeft.trackingTag.blockAction}`
                      }
                    >
                      {ctaLeft.ctaLabel}
                    </Button>
                  </ButtonContainer>
                )}
              </Stack>
            </HeadingContainer>
          </Col>
          <Col
            sizes={{
              base: 4,
              md: 7,
            }}
          >
            <ContentContainer>
              <Stack spacing="--spacing-large">
                <HeadingLevel>
                  {!!text && (
                    <BulletPoints
                      bulletPointStyle="hexagon"
                      pointColour={getBulletPointColour(backgroundColourToken)}
                    >
                      <PortableText
                        value={text}
                        audienceConfig={{
                          pageAudience,
                          isAudienceSwitcherEnabled,
                        }}
                      />
                    </BulletPoints>
                  )}
                  {!!ctaRight?.ctaAction && (
                    <ButtonContainer>
                      <Button
                        variant={getButtonComponentThemeFlat(
                          backgroundColourToken,
                        )}
                        href={blockUrlWithAudience(
                          ctaRight.ctaAction,
                          ctaRight.audienceRef,
                          pageAudience,
                          ctaRight.noAudienceToggle,
                          isAudienceSwitcherEnabled,
                        )}
                        aria-label={ctaRight.buttonAccessibleLabel}
                        className={
                          ctaRight.trackingTag?.blockName &&
                          `tracking-${ctaRight.trackingTag.blockName}__${ctaRight.trackingTag.blockAction}`
                        }
                      >
                        {ctaRight.ctaLabel}
                      </Button>
                    </ButtonContainer>
                  )}
                  <Stack spacing="--spacing-small">
                    {!!links &&
                      links.map((link) => (
                        <MappedLink
                          href={blockUrlWithAudience(
                            link.ctaAction,
                            link.audienceRef,
                            pageAudience,
                            link.noAudienceToggle,
                            isAudienceSwitcherEnabled,
                          )}
                          backgroundColour={backgroundColourToken}
                          key={link._key}
                        >
                          {link.ctaText}
                        </MappedLink>
                      ))}
                  </Stack>
                </HeadingLevel>
              </Stack>
            </ContentContainer>
          </Col>
        </HeadingLevel>
      </Row>
    </Section>
  );
};

const ButtonContainer = styled.div`
  a {
    margin: auto;
  }

  ${screen.md} {
    a {
      margin: 0;
    }
  }
`;

const HeadingContainer = styled.div`
  ${Heading} {
    text-align: left;
  }
`;

const ContentContainer = styled.div`
  ${Heading} {
    ${headingMedium}
    &:not(:first-of-type) {
      margin-top: var(--spacing-large);
    }
  }

  ${Paragraph}:not(:last-of-type) {
    margin-bottom: var(--spacing-xx-small);
  }
`;

const MappedLink = styled(BaseLink)`
  ${subheadingSmall}
`;
